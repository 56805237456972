import { http } from '@/api/http';
import { AxiosRequestConfig } from 'axios';
import { SpotActivityType } from '@/types/api/activity/spot-activity.type';
import { EventActivityType } from '@/types/api/activity/event-activity.type';
import {
  ActivityCreateLocaleRequest,
  ActivityCreateRequest, ActivityReferenceCreateRequest,
  ActivityReferenceUpdateRequest, ActivitySubCreateRequest, ActivitySubUpdateRequest, ActivityUpdateLocaleRequest
} from '@/types/request/activity-request.types';
import { ActivityType } from '@/types/api/info/activity.type';
import { ActivityLocaleType } from '@/types/api/info/activity-locale.type';
import { SubActivityType } from '@/types/api/info/sub-activity.type';
import { SubActivityLocaleType } from '@/types/api/info/sub-activity-locale.type';

export const activityGetById = (id: number, options: AxiosRequestConfig = {}): Promise<ActivityType> => {
  return http
    .get(`/admin/activity/${id}`, { ...options })
    .then(response => response.data);
};

export const activityCreate = (
  params: ActivityReferenceCreateRequest,
  options: AxiosRequestConfig = {}
): Promise<ActivityType> => {
  const formData = new FormData();

  if (params.image) {
    formData.append('image', params.image);
  }

  Object.entries(params).map(([key, value]) => {
    if (value && key !== 'image') {
      formData.set(key, value);
    }
  });

  return http
    .post('/admin/activity', formData, {
      ...options,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => response.data);
};

export const activityUpdate = (
  id: number,
  params: ActivityReferenceUpdateRequest,
  options: AxiosRequestConfig = {}
): Promise<ActivityType> => {
  const formData = new FormData();

  if (params.image) {
    formData.append('image', params.image);
  }

  Object.entries(params).map(([key, value]) => {
    if (value && key !== 'image') {
      formData.set(key, value);
    }
  });

  return http
    .put(`/admin/activity/${id}`, formData, {
      ...options,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => response.data);
};

export const activityDelete = (id: number, options: AxiosRequestConfig = {}): Promise<ActivityType> => {
  return http
    .delete(`/admin/activity/${id}`, { ...options })
    .then(response => response.data);
};

export const activityCreateLocale = (
  id: number,
  params: ActivityCreateLocaleRequest,
  options: AxiosRequestConfig = {}
): Promise<ActivityLocaleType> => {
  return http
    .put(`/admin/activity/${id}/locale`, params, { ...options })
    .then(response => response.data);
};

export const activityUpdateLocale = (
  id: number,
  params: ActivityUpdateLocaleRequest,
  options: AxiosRequestConfig = {}
): Promise<ActivityLocaleType> => {
  return http
    .put(`/admin/activity/locale/${id}`, params, { ...options })
    .then(response => response.data);
};

export const activityDeleteLocale = (id: number, options: AxiosRequestConfig = {}): Promise<ActivityLocaleType> => {
  return http.delete(`/admin/activity/locale/${id}`, { ...options })
    .then(response => response.data);
};

export const activityEventCreate = (
  id: number,
  params: ActivityCreateRequest,
  options: AxiosRequestConfig = {}
): Promise<EventActivityType> => {
  return http
    .post(`/activity/event/${id}`, params, { ...options })
    .then(response => response.data);
};

export const activitySpotCreate = (
  id: number,
  params: ActivityCreateRequest,
  options: AxiosRequestConfig = {}
): Promise<SpotActivityType> => {
  return http
    .post(`/activity/spot/${id}`, params, { ...options })
    .then(response => response.data);
};

export const activityEventDelete = (id: number, options: AxiosRequestConfig = {}): Promise<EventActivityType> => {
  return http
    .delete(`/activity/event/${id}`, { ...options })
    .then(response => response.data);
};

export const activitySpotDelete = (id: number, options: AxiosRequestConfig = {}): Promise<SpotActivityType> => {
  return http
    .delete(`/activity/spot/${id}`, { ...options })
    .then(response => response.data);
};

export const activitySubCreate = (
  params: ActivitySubCreateRequest,
  options: AxiosRequestConfig = {}
): Promise<SubActivityType> => {
  const formData = new FormData();

  if (params.image) {
    formData.append('image', params.image);
  }

  Object.entries(params).map(([key, value]) => {
    if (value && key !== 'image') {
      formData.set(key, value);
    }
  });

  return http
    .post('/admin/subactivity', formData, {
      ...options,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => response.data);
};

export const activitySubUpdate = (
  id: number,
  params: ActivitySubUpdateRequest,
  options: AxiosRequestConfig = {}
): Promise<SubActivityType> => {
  const formData = new FormData();

  if (params.image) {
    formData.append('image', params.image);
  }

  Object.entries(params).map(([key, value]) => {
    if (value && key !== 'image') {
      formData.set(key, value);
    }
  });

  return http
    .put(`/admin/subactivity/${id}`, formData, {
      ...options,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => response.data);
};

export const activitySubDelete = (id: number, options: AxiosRequestConfig = {}): Promise<SubActivityType> => {
  return http
    .delete(`/admin/subactivity/${id}`, { ...options })
    .then(response => response.data);
};

export const activitySubCreateLocale = (
  id: number,
  params: ActivityCreateLocaleRequest,
  options: AxiosRequestConfig = {}
): Promise<SubActivityLocaleType> => {
  return http
    .put(`/admin/subactivity/${id}/locale`, params, { ...options })
    .then(response => response.data);
};

export const activitySubUpdateLocale = (
  id: number,
  params: ActivityUpdateLocaleRequest,
  options: AxiosRequestConfig = {}
): Promise<SubActivityLocaleType> => {
  return http
    .put(`/admin/subactivity/locale/${id}`, params, { ...options })
    .then(response => response.data);
};
