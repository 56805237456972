import { http } from '@/api/http';
import { AxiosRequestConfig } from 'axios';
import { ListResponseType } from '@/types/response/list-response.type';
import {
  SpotCreateRequest,
  SpotRequest,
  SpotUpdateLocaleRequest,
  SpotUpdateRequest
} from '@/types/request/spot-request.types';
import { SpotType } from '@/types/api/spot/spot.type';
import { SpotLocaleType } from '@/types/api/spot/spot-locale.type';

export const spotGetList = (
  params: SpotRequest,
  options: AxiosRequestConfig = {}
): Promise<ListResponseType<SpotType>> => {
  return http
    .get('/admin/spot', { params, ...options })
    .then(response => response.data);
};

export const spotGetById = (id: number, options: AxiosRequestConfig = {}): Promise<SpotType> => {
  return http
    .get(`/admin/spot/${id}`, { ...options })
    .then(response => response.data);
};

export const spotCreate = (
  params: SpotCreateRequest,
  options: AxiosRequestConfig = {}
): Promise<SpotType> => {
  const formData = new FormData();

  params.image.forEach(item => {
    formData.append('image', item);
  });

  if (params.schedule && params.schedule.length) {
    formData.append('schedule', params.schedule.map(item => JSON.stringify(item)).join(';'));
  }

  if (params.appMeta && params.appMeta.length) {
    formData.append('appMeta', JSON.stringify(params.appMeta));
  }

  Object.entries(params).map(([key, value]) => {
    if (value && key !== 'image' && key !== 'schedule' && key !== 'appMeta') {
      formData.set(key, value);
    }
  });

  return http
    .post('/admin/spot', formData, {
      ...options,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => response.data);
};

export const spotUpdate = (
  id: number,
  params: SpotUpdateRequest,
  options: AxiosRequestConfig = {}
): Promise<SpotType> => {
  const formData = new FormData();

  if (params.image) {
    params.image.forEach(item => {
      formData.append('image', item);
    });
  }

  if (params.schedule && params.schedule.length) {
    formData.append('schedule', params.schedule.map(item => JSON.stringify(item)).join(';'));
  }

  if (params.appMeta && params.appMeta.length) {
    formData.append('appMeta', JSON.stringify(params.appMeta));
  }

  Object.entries(params).map(([key, value]) => {
    if (value !== undefined && key !== 'image' && key !== 'schedule' && key !== 'appMeta') {
      formData.set(key, value);
    }
  });

  return http
    .put(`/admin/spot/${id}`, formData, {
      ...options,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => response.data);
};

export const spotDelete = (id: number, options: AxiosRequestConfig = {}): Promise<SpotType> => {
  return http
    .delete(`/admin/spot/${id}`, { ...options })
    .then(response => response.data);
};

export const spotCreateLocale = (
  id: number,
  params: SpotUpdateLocaleRequest,
  options: AxiosRequestConfig = {}
): Promise<SpotLocaleType> => {
  return http
    .put(`/admin/spot/${id}/locale`, params, { ...options })
    .then(response => response.data);
};

export const spotUpdateLocale = (
  id: number,
  params: SpotUpdateLocaleRequest,
  options: AxiosRequestConfig = {}
): Promise<SpotLocaleType> => {
  return http
    .put(`/admin/spot/locale/${id}`, params, { ...options })
    .then(response => response.data);
};

export const spotDeleteLocale = (id: number, options: AxiosRequestConfig = {}): Promise<SpotLocaleType> => {
  return http.delete(`/admin/spot/locale/${id}`, { ...options })
    .then(response => response.data);
};

export const spotUpdateImages = (id: number, params: File[], options: AxiosRequestConfig = {}): Promise<SpotType> => {
  const formData = new FormData();

  if (params) {
    params.forEach(item => {
      formData.append('image', item);
    });
  }

  return http
    .put(`/admin/spot/${id}/image`, formData, {
      ...options,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => response.data);
};

export const spotDeleteImage = (id: number, imageId: string, options: AxiosRequestConfig = {}): Promise<SpotType> => {
  return http.delete(`/admin/spot/${id}/image/${imageId}`, { ...options })
    .then(response => response.data);
};
