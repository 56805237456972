import { auth } from './auth';
import { lang } from './lang';
import { events } from './events';
import { users } from './users';
import { spot } from './spot';
import { venues } from './venues';
import { info } from './info';
import { activity } from './activity';
import { stay } from './stay';
import { offer } from './offer';

export default {
  auth,
  lang,
  events,
  users,
  spot,
  venues,
  info,
  activity,
  stay,
  offer,
}
