import { GetterTree } from 'vuex';
import { StoreType } from '@/types/store/store.type';
import { EventsStoreType } from '@/types/store/events-store.type';

export const getters: GetterTree<EventsStoreType, StoreType> = {
  getEvents(state) {
    return state.events;
  },

  getEvent(state) {
    return state.event;
  },
};
