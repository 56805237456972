import { MutationTree } from 'vuex';
import { UsersStoreType } from '@/types/store/users-store.type';

export const mutations: MutationTree<UsersStoreType> = {
  SET_USERS(state, newValue) {
    state.users = newValue;
  },

  SET_USER(state, newValue) {
    state.user = newValue;
  },

  SET_USER_STATISTICS(state, newValue) {
    state.userStatistics = newValue;
  },
};
