import { GetterTree } from 'vuex';
import { StoreType } from '@/types/store/store.type';
import { SpotStoreType } from '@/types/store/spot-store.type';

export const getters: GetterTree<SpotStoreType, StoreType> = {
  getSpots(state) {
    return state.spots;
  },

  getSpot(state) {
    return state.spot;
  },
};
