import { ActionTree } from 'vuex';
import { StoreType } from '@/types/store/store.type';
import { EventsStoreType } from '@/types/store/events-store.type';
import {
  EventsCreateRequest,
  EventsRequest, EventsUpdateLocaleRequest,
  EventsUpdateRequest
} from '@/types/request/events-request.types';
import { ListResponseType } from '@/types/response/list-response.type';
import { EventType } from '@/types/api/events/event.type';
import {
  eventsCreate,
  eventsCreateLocale,
  eventsDelete, eventsDeleteImage, eventsDeleteLocale,
  eventsGetById,
  eventsGetList,
  eventsUpdate, eventsUpdateImages, eventsUpdateLocale
} from '@/api/events.api';
import { AgGridResponse } from '@/helpers/ag-grid/ag-grid-response';
import { EventLocaleType } from '@/types/api/events/event-locale.type';

export type EventCard<T> = {
  id: number;
  params: T;
}

export const actions: ActionTree<EventsStoreType, StoreType> = {
  getEvents({ commit }, params: EventsRequest): Promise<ListResponseType<EventType>> {
    return eventsGetList(params)
      .then(result => {
        if (result) {
          commit('SET_EVENTS', result);
        }

        return result || AgGridResponse;
      })
      .catch(() => AgGridResponse);
  },

  getEvent({ commit }, id: number): Promise<EventType> {
    return eventsGetById(id)
      .then(result => {
        if (result) {
          commit('SET_EVENT', result);
        }

        return result;
      });
  },

  createEvent({ commit }, params: EventsCreateRequest): Promise<EventType> {
    return eventsCreate(params).then(result => result);
  },

  updateEvent({ commit }, data: EventCard<EventsUpdateRequest>): Promise<EventType> {
    return eventsUpdate(data.id, data.params).then(result => result);
  },

  deleteEvent({ commit }, id: number): Promise<EventType> {
    return eventsDelete(id).then(result => result);
  },

  createEventLocale({ commit }, data: EventCard<EventsUpdateLocaleRequest>): Promise<EventLocaleType> {
    return eventsCreateLocale(data.id, data.params).then(result => result);
  },

  updateEventLocale({ commit }, data: EventCard<EventsUpdateLocaleRequest>): Promise<EventLocaleType> {
    return eventsUpdateLocale(data.id, data.params).then(result => result);
  },

  deleteEventLocale({ commit }, id: number): Promise<EventLocaleType> {
    return eventsDeleteLocale(id).then(result => result);
  },

  updateImages({ commit }, data: EventCard<File[]>): Promise<EventType> {
    return eventsUpdateImages(data.id, data.params)
      .then(result => result);
  },

  deleteImage({ commit }, data: EventCard<string>): Promise<EventType> {
    return eventsDeleteImage(data.id, data.params)
      .then(result => result);
  },
};
