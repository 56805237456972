import { ActionTree } from 'vuex';
import { StoreType } from '@/types/store/store.type';
import { ListResponseType } from '@/types/response/list-response.type';
import { AgGridResponse } from '@/helpers/ag-grid/ag-grid-response';
import { OfferStoreType } from '@/types/store/offer-store.type';
import {
  OfferChangeEventOfferStatusRequest,
  OfferChangeSpotOfferStatusRequest,
  OfferCreateEventRequest,
  OfferCreateSpotRequest, OfferEventRequest, OfferEventUsersRequest,
  OfferSpotRequest, OfferSpotUsersRequest,
  OfferUpdateEventRequest, OfferUpdateLocaleRequest,
  OfferUpdateSpotRequest
} from '@/types/request/offer-request.types';
import { SpotOfferType } from '@/types/api/offer/spot-offer.type';
import {
  offerEvent,
  offerEventCreate,
  offerEventCreateLocale,
  offerEventDelete,
  offerEventList,
  offerEventUpdate,
  offerEventUpdateLocale,
  offerEventUserDelete,
  offerEventUserList,
  offerEventUserStatusChange,
  offerSpot,
  offerSpotCreate,
  offerSpotCreateLocale,
  offerSpotDelete,
  offerSpotList,
  offerSpotUpdate,
  offerSpotUpdateLocale,
  offerSpotUserDelete,
  offerSpotUserList,
  offerSpotUserStatusChange
} from '@/api/offer.api';
import { SpotOfferLocaleType } from '@/types/api/offer/spot-offer-locale.type';
import { EventOfferType } from '@/types/api/offer/event-offer.type';
import { EventOfferLocaleType } from '@/types/api/offer/event-offer-locale.type';
import { SpotOfferUserType } from '@/types/api/offer/spot-offer-user.type';
import { EventOfferUserType } from '@/types/api/offer/event-offer-user.type';

export type IOfferCard<T> = {
  id: number;
  params: T;
}

export const actions: ActionTree<OfferStoreType, StoreType> = {
  getOfferSpots({ commit }, data: IOfferCard<OfferSpotRequest>): Promise<ListResponseType<SpotOfferType>> {
    return offerSpotList(data.id, data.params)
      .then(result => {
        if (result) {
          commit('SET_SPOT_OFFERS', result);
        }
        return result || AgGridResponse;
      })
      .catch(() => AgGridResponse);
  },

  getOfferSpot({ commit }, id: number): Promise<SpotOfferType> {
    return offerSpot(id)
      .then(result => {
        if (result) {
          commit('SET_SPOT_OFFER', result);
        }
        return result;
      });
  },

  createOfferSpot({ commit }, params: OfferCreateSpotRequest): Promise<SpotOfferType> {
    return offerSpotCreate(params).then(result => result);
  },

  updateOfferSpot({ commit }, data: IOfferCard<OfferUpdateSpotRequest>): Promise<SpotOfferType> {
    return offerSpotUpdate(data.id, data.params).then(result => result);
  },

  deleteOfferSpot({ commit }, id: number): Promise<SpotOfferType> {
    return offerSpotDelete(id).then(result => result);
  },

  createOfferSpotLocale({ commit }, data: IOfferCard<OfferUpdateLocaleRequest>): Promise<SpotOfferLocaleType> {
    return offerSpotCreateLocale(data.id, data.params).then(result => result);
  },

  updateOfferSpotLocale({ commit }, data: IOfferCard<OfferUpdateLocaleRequest>): Promise<SpotOfferLocaleType> {
    return offerSpotUpdateLocale(data.id, data.params).then(result => result);
  },

  getOfferEvents({ commit }, data: IOfferCard<OfferEventRequest>): Promise<ListResponseType<EventOfferType>> {
    return offerEventList(data.id, data.params)
      .then(result => {
        if (result) {
          commit('SET_EVENT_OFFERS', result);
        }
        return result || AgGridResponse;
      })
      .catch(() => AgGridResponse);
  },

  getOfferEvent({ commit }, id: number): Promise<EventOfferType> {
    return offerEvent(id)
      .then(result => {
        if (result) {
          commit('SET_EVENT_OFFER', result);
        }
        return result;
      });
  },

  createOfferEvent({ commit }, params: OfferCreateEventRequest): Promise<EventOfferType> {
    return offerEventCreate(params).then(result => result);
  },

  updateOfferEvent({ commit }, data: IOfferCard<OfferUpdateEventRequest>): Promise<EventOfferType> {
    return offerEventUpdate(data.id, data.params).then(result => result);
  },

  deleteOfferEvent({ commit }, id: number): Promise<EventOfferType> {
    return offerEventDelete(id).then(result => result);
  },

  createOfferEventLocale({ commit }, data: IOfferCard<OfferUpdateLocaleRequest>): Promise<EventOfferLocaleType> {
    return offerEventCreateLocale(data.id, data.params).then(result => result);
  },

  updateOfferEventLocale({ commit }, data: IOfferCard<OfferUpdateLocaleRequest>): Promise<EventOfferLocaleType> {
    return offerEventUpdateLocale(data.id, data.params).then(result => result);
  },

  getOfferSpotUsers({ commit }, data: IOfferCard<OfferSpotUsersRequest>): Promise<ListResponseType<SpotOfferUserType>> {
    return offerSpotUserList(data.id, data.params)
      .then(result => {
        if (result) {
          commit('SET_SPOT_OFFER_USERS', result);
        }
        return result || AgGridResponse;
      })
      .catch(() => AgGridResponse);
  },

  getOfferEventUsers({ commit }, data: IOfferCard<OfferEventUsersRequest>): Promise<ListResponseType<EventOfferUserType>> {
    return offerEventUserList(data.id, data.params)
      .then(result => {
        if (result) {
          commit('SET_EVENT_OFFER_USERS', result);
        }
        return result || AgGridResponse;
      })
      .catch(() => AgGridResponse);
  },

  deleteOfferSpotUser({ commit }, id: number): Promise<SpotOfferUserType> {
    return offerSpotUserDelete(id).then(result => result);
  },

  deleteOfferEventUser({ commit }, id: number): Promise<EventOfferUserType> {
    return offerEventUserDelete(id).then(result => result);
  },

  changeOfferSpotUserStatus({ commit }, data: IOfferCard<OfferChangeSpotOfferStatusRequest>): Promise<SpotOfferUserType> {
    return offerSpotUserStatusChange(data.id, data.params).then(result => result);
  },

  changeOfferEventUserStatus({ commit }, data: IOfferCard<OfferChangeEventOfferStatusRequest>): Promise<EventOfferUserType> {
    return offerEventUserStatusChange(data.id, data.params).then(result => result);
  },
};
