import { OfferStoreType } from '@/types/store/offer-store.type';

export const state: OfferStoreType = {
  spotOffers: null,
  spotOffer: null,
  spotOfferUsers: null,
  eventOffers: null,
  eventOffer: null,
  eventOfferUsers: null,
};
