import { GetterTree } from 'vuex';
import { AuthStoreType } from '@/types/store/auth-store.type';
import { StoreType } from '@/types/store/store.type';

export const getters: GetterTree<AuthStoreType, StoreType> = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return state.currentUser;
  },

  getCurrentUser(state) {
    return state.currentUser;
  }
};
