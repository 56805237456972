import { ActionTree } from 'vuex';
import { StoreType } from '@/types/store/store.type';
import { ListResponseType } from '@/types/response/list-response.type';
import { AgGridResponse } from '@/helpers/ag-grid/ag-grid-response';
import { VenueStoreType } from '@/types/store/venue-store.type';
import {
  VenuesCreateRequest,
  VenuesLinkSpotRequest,
  VenuesLinkStayRequest,
  VenuesRequest, VenuesUpdateRequest
} from '@/types/request/venue-request.types';
import { VenueType } from '@/types/api/venue/venue.type';
import {
  venuesCreate,
  venuesDelete, venuesGetById,
  venuesGetList,
  venuesLinkSpot,
  venuesLinkStay,
  venuesUnlinkStay, venuesUpdate
} from '@/api/venues.api';

export type VenueCard<T> = {
  id: number;
  params: T;
}

export const actions: ActionTree<VenueStoreType, StoreType> = {
  getVenues({ commit }, params: VenuesRequest): Promise<ListResponseType<VenueType>> {
    return venuesGetList(params)
      .then(result => {
        if (result) {
          commit('SET_VENUES', result);
        }

        return result || AgGridResponse;
      })
      .catch(() => AgGridResponse);
  },

  getVenue({ commit }, id: number): Promise<VenueType> {
    return venuesGetById(id)
      .then(result => {
        if (result) {
          commit('SET_VENUE', result);
        }

        return result;
      });
  },

  createVenue({ commit }, params: VenuesCreateRequest): Promise<VenueType> {
    return venuesCreate(params).then(result => result);
  },

  updateVenue({ commit }, data: VenueCard<VenuesUpdateRequest>): Promise<VenueType> {
    return venuesUpdate(data.id, data.params).then(result => result);
  },

  deleteVenue({ commit }, id: number): Promise<VenueType> {
    return venuesDelete(id).then(result => result);
  },

  linkSpot({ commit }, params: VenuesLinkSpotRequest): Promise<VenueType> {
    return venuesLinkSpot(params).then(result => result);
  },

  linkStay({ commit }, params: VenuesLinkStayRequest): Promise<VenueType> {
    return venuesLinkStay(params).then(result => result);
  },

  unlinkStay({ commit }, params: VenuesLinkStayRequest): Promise<VenueType> {
    return venuesUnlinkStay(params).then(result => result);
  },
};
