import { state } from './state';
import { actions } from './actions';
import { mutations } from './mutations';
import { getters } from './getters';
import { Module } from 'vuex';
import { StoreType } from '@/types/store/store.type';
import { UsersStoreType } from '@/types/store/users-store.type';

const namespaced: boolean = true;
export const users: Module<UsersStoreType, StoreType> = {
  namespaced,
  state,
  actions,
  mutations,
  getters
};
