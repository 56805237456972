import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { enUS, isAvailableLang, ruRU } from '@/i18n';
import { LanguageType } from '@/types/api/info/language.type';

@Component
export default class LangWidget extends Vue {
  selected = '';
  options = [
    { value: ruRU, text: 'RU' },
    { value: enUS, text: 'EN' },
  ];

  @Prop({
    type: Boolean,
    required: false,
    default: () => false
  })
  filled;

  @Getter('getLanguages', { namespace: 'info' })
  languages?: LanguageType[];

  @Getter('getCurrentLang', { namespace: 'lang' })
  currentLang!: string;

  @Action('changeLang', { namespace: 'lang' })
  changeLang!: (lang: string) => Promise<void>;

  created(): void {
    if (this.languages) {
      this.options = this.languages
        .filter(item => isAvailableLang(item.code))
        .map(item => ({ value: item.code, text: item.code.toUpperCase() }));
    }

    this.selected = this.currentLang;
  }

  onLangChange() {
    this.changeLang(this.selected);
  }
}
