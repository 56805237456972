import {
  required,
  confirmed,
  length,
  email,
  min,
  max,
  min_value,
  max_value,
  size,
  numeric
} from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
import { i18n } from '@/i18n';
import appConfig from '@/app.config.json';

extend('numeric', {
  ...numeric,
  message: () => i18n.t('validation.numeric') as string
});

extend('required', {
  ...required,
  message: () => i18n.t('validation.required') as string
});

extend('email', {
  ...email,
  message: () => i18n.t('validation.email') as string
});

extend('confirmed', {
  ...confirmed,
  message: () => i18n.t('validation.confirmed') as string
});

extend('length', {
  ...length,
  message: (_, params) => i18n.t('validation.length', params) as string
});

extend('min', {
  ...min,
  message: (_, params) => i18n.t('validation.min', params) as string
});

extend('max', {
  ...max,
  message: (_, params) => i18n.t('validation.max', params) as string
});

extend('min_value', {
  ...min_value,
  message: (_, params) => i18n.t('validation.min_value', params) as string
});

extend('max_value', {
  ...max_value,
  message: (_, params) => i18n.t('validation.max_value', params) as string
});

// Less than
extend('lt', {
  validate: (value, args: any) => value < Number(args.max),
  params: ['max'],
  message: (value, params) => i18n.t('validation.lessThanValue', { max: params!.max }) as string
});

// More than
extend('mt', {
  validate: (value, args: any) => value > Number(args.min),
  params: ['min'],
  message: (value, params) => i18n.t('validation.moreThanValue', { min: params!.min }) as string
});

// Max file size in KB
extend('maxFileSize', {
  validate: (files: File | File[]) => size.validate(files, { size: appConfig.maxFileSize }),
  params: size.params,
  message: () => i18n.t('validation.size', { size: appConfig.maxFileSize }) as string
});

// Max array length
extend('maxArrLength', {
  validate: (value, args: any) => value.length <= Number(args.length),
  params: ['length'],
  message: (value, params) => i18n.t('validation.maxLength', { length: params!.length }) as string
});
