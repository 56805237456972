import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import modules from './modules';
import { StoreType } from '@/types/store/store.type';
import { dispatchActionForAllModules } from '@/helpers/dispatch-action-for-all-modules';

Vue.use(Vuex);

const storeOptions: StoreOptions<StoreType> = {
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',
};

export const store = new Vuex.Store<StoreType>(storeOptions);

// Automatically run the `init` action for every module,
// if one exists.
dispatchActionForAllModules('init');
