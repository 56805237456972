import { MutationTree } from 'vuex';
import { OfferStoreType } from '@/types/store/offer-store.type';

export const mutations: MutationTree<OfferStoreType> = {
  SET_SPOT_OFFERS(state, newValue) {
    state.spotOffers = newValue;
  },

  SET_SPOT_OFFER(state, newValue) {
    state.spotOffer = newValue;
  },

  SET_SPOT_OFFER_USERS(state, newValue) {
    state.spotOfferUsers = newValue;
  },

  SET_EVENT_OFFERS(state, newValue) {
    state.eventOffers = newValue;
  },

  SET_EVENT_OFFER(state, newValue) {
    state.eventOffer = newValue;
  },

  SET_EVENT_OFFER_USERS(state, newValue) {
    state.eventOfferUsers = newValue;
  },
};
