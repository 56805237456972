import { GetterTree } from 'vuex';
import { StoreType } from '@/types/store/store.type';
import { UsersStoreType } from '@/types/store/users-store.type';

export const getters: GetterTree<UsersStoreType, StoreType> = {
  getUsers(state) {
    return state.users;
  },

  getUser(state) {
    return state.user;
  },

  getUserStatistics(state) {
    return state.userStatistics;
  },
};
