import { http } from '@/api/http';
import { AxiosRequestConfig } from 'axios';
import { ListResponseType } from '@/types/response/list-response.type';
import { StayRequest } from '@/types/request/stay-request.types';
import { StayType } from '@/types/api/stay/stay.type';

export const stayGetList = (
  params: StayRequest,
  options: AxiosRequestConfig = {}
): Promise<ListResponseType<StayType>> => {
  return http
    .get('/admin/stay', { params, ...options })
    .then(response => response.data);
};
