import { MutationTree } from 'vuex';
import { SpotStoreType } from '@/types/store/spot-store.type';

export const mutations: MutationTree<SpotStoreType> = {
  SET_SPOTS(state, newValue) {
    state.spots = newValue;
  },

  SET_SPOT(state, newValue) {
    state.spot = newValue;
  },
};
