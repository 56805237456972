import { http } from '@/api/http';
import { AxiosRequestConfig } from 'axios';
import { ListResponseType } from '@/types/response/list-response.type';
import {
  OfferChangeEventOfferStatusRequest,
  OfferChangeSpotOfferStatusRequest,
  OfferCreateEventRequest,
  OfferCreateSpotRequest, OfferEventRequest, OfferEventUsersRequest,
  OfferSpotRequest, OfferSpotUsersRequest,
  OfferUpdateEventRequest, OfferUpdateLocaleRequest,
  OfferUpdateSpotRequest
} from '@/types/request/offer-request.types';
import { SpotOfferType } from '@/types/api/offer/spot-offer.type';
import { SpotOfferLocaleType } from '@/types/api/offer/spot-offer-locale.type';
import { EventOfferType } from '@/types/api/offer/event-offer.type';
import { EventOfferLocaleType } from '@/types/api/offer/event-offer-locale.type';
import { SpotOfferUserType } from '@/types/api/offer/spot-offer-user.type';
import { EventOfferUserType } from '@/types/api/offer/event-offer-user.type';

export const offerSpotList = (
  id: number,
  params: OfferSpotRequest,
  options: AxiosRequestConfig = {}
): Promise<ListResponseType<SpotOfferType>> => {
  return http
    .get(`/admin/offer/spot-list/${id}`, { params, ...options })
    .then(response => response.data);
};

export const offerSpot = (id: number, options: AxiosRequestConfig = {}): Promise<SpotOfferType> => {
  return http
    .get(`/admin/offer/spot/${id}`, { ...options })
    .then(response => response.data);
};

export const offerSpotCreate = (
  params: OfferCreateSpotRequest,
  options: AxiosRequestConfig = {}
): Promise<SpotOfferType> => {
  return http
    .post('/admin/offer/spot', params, { ...options })
    .then(response => response.data);
};

export const offerSpotUpdate = (
  id: number,
  params: OfferUpdateSpotRequest,
  options: AxiosRequestConfig = {}
): Promise<SpotOfferType> => {
  return http
    .put(`/admin/offer/spot/${id}`, params, { ...options })
    .then(response => response.data);
};

export const offerSpotDelete = (id: number, options: AxiosRequestConfig = {}): Promise<SpotOfferType> => {
  return http
    .delete(`/admin/offer/spot/${id}`, { ...options })
    .then(response => response.data);
};

export const offerSpotCreateLocale = (
  id: number,
  params: OfferUpdateLocaleRequest,
  options: AxiosRequestConfig = {}
): Promise<SpotOfferLocaleType> => {
  return http
    .put(`/admin/offer/spot/${id}/locale`, params, { ...options })
    .then(response => response.data);
};

export const offerSpotUpdateLocale = (
  id: number,
  params: OfferUpdateLocaleRequest,
  options: AxiosRequestConfig = {}
): Promise<SpotOfferLocaleType> => {
  return http
    .put(`/admin/offer/spot/locale/${id}`, params, { ...options })
    .then(response => response.data);
};

export const offerEventList = (
  id: number,
  params: OfferEventRequest,
  options: AxiosRequestConfig = {}
): Promise<ListResponseType<EventOfferType>> => {
  return http
    .get(`/admin/offer/event-list/${id}`, { params, ...options })
    .then(response => response.data);
};

export const offerEvent = (id: number, options: AxiosRequestConfig = {}): Promise<EventOfferType> => {
  return http
    .get(`/admin/offer/event/${id}`, { ...options })
    .then(response => response.data);
};

export const offerEventCreate = (
  params: OfferCreateEventRequest,
  options: AxiosRequestConfig = {}
): Promise<EventOfferType> => {
  return http
    .post('/admin/offer/event', params, { ...options })
    .then(response => response.data);
};

export const offerEventUpdate = (
  id: number,
  params: OfferUpdateEventRequest,
  options: AxiosRequestConfig = {}
): Promise<EventOfferType> => {
  return http
    .put(`/admin/offer/event/${id}`, params, { ...options })
    .then(response => response.data);
};

export const offerEventDelete = (id: number, options: AxiosRequestConfig = {}): Promise<EventOfferType> => {
  return http
    .delete(`/admin/offer/event/${id}`, { ...options })
    .then(response => response.data);
};

export const offerEventCreateLocale = (
  id: number,
  params: OfferUpdateLocaleRequest,
  options: AxiosRequestConfig = {}
): Promise<EventOfferLocaleType> => {
  return http
    .put(`/admin/offer/event/${id}/locale`, params, { ...options })
    .then(response => response.data);
};

export const offerEventUpdateLocale = (
  id: number,
  params: OfferUpdateLocaleRequest,
  options: AxiosRequestConfig = {}
): Promise<EventOfferLocaleType> => {
  return http
    .put(`/admin/offer/event/locale/${id}`, params, { ...options })
    .then(response => response.data);
};

export const offerSpotUserList = (
  id: number,
  params: OfferSpotUsersRequest,
  options: AxiosRequestConfig = {}
): Promise<ListResponseType<SpotOfferUserType>> => {
  return http
    .get(`/admin/offer/spot-user/${id}`, { params, ...options })
    .then(response => response.data);
};

export const offerEventUserList = (
  id: number,
  params: OfferEventUsersRequest,
  options: AxiosRequestConfig = {}
): Promise<ListResponseType<EventOfferUserType>> => {
  return http
    .get(`/admin/offer/event-user/${id}`, { params, ...options })
    .then(response => response.data);
};

export const offerSpotUserDelete = (id: number, options: AxiosRequestConfig = {}): Promise<SpotOfferUserType> => {
  return http
    .delete(`/admin/offer/spot-user/${id}`, { ...options })
    .then(response => response.data);
};

export const offerEventUserDelete = (id: number, options: AxiosRequestConfig = {}): Promise<EventOfferUserType> => {
  return http
    .delete(`/admin/offer/event-user/${id}`, { ...options })
    .then(response => response.data);
};

export const offerSpotUserStatusChange = (
  id: number,
  params: OfferChangeSpotOfferStatusRequest,
  options: AxiosRequestConfig = {}
): Promise<SpotOfferUserType> => {
  return http
    .put(`/admin/offer/spot-user/${id}/status`, params, { ...options })
    .then(response => response.data);
};

export const offerEventUserStatusChange = (
  id: number,
  params: OfferChangeEventOfferStatusRequest,
  options: AxiosRequestConfig = {}
): Promise<EventOfferUserType> => {
  return http
    .put(`/admin/offer/event-user/${id}/status`, params, { ...options })
    .then(response => response.data);
};
