import { MutationTree } from 'vuex';
import { EventsStoreType } from '@/types/store/events-store.type';

export const mutations: MutationTree<EventsStoreType> = {
  SET_EVENTS(state, newValue) {
    state.events = newValue;
  },

  SET_EVENT(state, newValue) {
    state.event = newValue;
  },
};
