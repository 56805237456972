import { ActionTree } from 'vuex';
import { StoreType } from '@/types/store/store.type';
import { ListResponseType } from '@/types/response/list-response.type';
import { AgGridResponse } from '@/helpers/ag-grid/ag-grid-response';
import { SpotStoreType } from '@/types/store/spot-store.type';
import {
  SpotCreateRequest,
  SpotRequest,
  SpotUpdateLocaleRequest,
  SpotUpdateRequest
} from '@/types/request/spot-request.types';
import { SpotType } from '@/types/api/spot/spot.type';
import {
  spotCreate,
  spotCreateLocale,
  spotDelete, spotDeleteImage, spotDeleteLocale,
  spotGetById,
  spotGetList,
  spotUpdate, spotUpdateImages,
  spotUpdateLocale
} from '@/api/spot.api';
import { SpotLocaleType } from '@/types/api/spot/spot-locale.type';

export type SpotCard<T> = {
  id: number;
  params: T;
}

export const actions: ActionTree<SpotStoreType, StoreType> = {
  getSpots({ commit }, params: SpotRequest): Promise<ListResponseType<SpotType>> {
    return spotGetList(params)
      .then(result => {
        if (result) {
          commit('SET_SPOTS', result);
        }

        return result || AgGridResponse;
      })
      .catch(() => AgGridResponse);
  },

  getSpot({ commit }, id: number): Promise<SpotType> {
    return spotGetById(id)
      .then(result => {
        if (result) {
          commit('SET_SPOT', result);
        }

        return result;
      });
  },

  createSpot({ commit }, params: SpotCreateRequest): Promise<SpotType> {
    return spotCreate(params).then(result => result);
  },

  updateSpot({ commit }, data: SpotCard<SpotUpdateRequest>): Promise<SpotType> {
    return spotUpdate(data.id, data.params).then(result => result);
  },

  deleteSpot({ commit }, id: number): Promise<SpotType> {
    return spotDelete(id).then(result => result);
  },

  createSpotLocale({ commit }, data: SpotCard<SpotUpdateLocaleRequest>): Promise<SpotLocaleType> {
    return spotCreateLocale(data.id, data.params).then(result => result);
  },

  updateSpotLocale({ commit }, data: SpotCard<SpotUpdateLocaleRequest>): Promise<SpotLocaleType> {
    return spotUpdateLocale(data.id, data.params).then(result => result);
  },

  deleteSpotLocale({ commit }, id: number): Promise<SpotLocaleType> {
    return spotDeleteLocale(id).then(result => result);
  },

  updateImages({ commit }, data: SpotCard<File[]>): Promise<SpotType> {
    return spotUpdateImages(data.id, data.params)
      .then(result => result);
  },

  deleteImage({ commit }, data: SpotCard<string>): Promise<SpotType> {
    return spotDeleteImage(data.id, data.params)
      .then(result => result);
  },
};
