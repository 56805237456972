import { Component, Vue, Prop } from 'vue-property-decorator';
import MetisMenu from 'metismenujs/dist/metismenujs';
import LangWidget from '@/components/ui/lang-widget/langWidget.vue';
import { UserType } from '@/types/api/user/user.type';
import { Getter } from 'vuex-class';

@Component({
  name: 'SideNav',
  components: {
    LangWidget
  }
})
export default class SideNav extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  isCondensed?: boolean;

  @Getter('getCurrentUser', { namespace: 'auth' })
  user!: UserType;

  mounted() {
    // eslint-disable-next-line no-unused-vars
    const menuRef = new MetisMenu('#side-menu');
    const activeElement = menuRef.element.querySelector('.mm-active');

    if (this.isCondensed && activeElement) {
      const showElement = activeElement.querySelector('.mm-show');
      if (showElement) {
        showElement.classList.remove('mm-show');
      }
    }
  }

  isActive(route) {
    return this.$route.name === route ? 'active' : '';
  }

  getSettingsRoutes() {
    return ['activities', 'stays'];
  }

  get userName() {
    return this.user.firstName || this.user.lastName
      ? `${this.user.firstName || ''} ${this.user.lastName || ''}` : this.user.phoneNumber;
  }

  toggleMenu() {
    if (this.$parent?.$parent) {
      const toggleFunc = (this.$parent.$parent as any).toggleMenu || (this.$parent.$parent.$parent as any).toggleMenu;
      toggleFunc();
    }
  }
}
