import { ActionTree } from 'vuex';
import { StoreType } from '@/types/store/store.type';
import { ListResponseType } from '@/types/response/list-response.type';
import { AgGridResponse } from '@/helpers/ag-grid/ag-grid-response';
import { StayStoreType } from '@/types/store/stay-store.type';
import { StayRequest } from '@/types/request/stay-request.types';
import { StayType } from '@/types/api/stay/stay.type';
import { stayGetList } from '@/api/stay.api';

export const actions: ActionTree<StayStoreType, StoreType> = {
  getStays({ commit }, params: StayRequest): Promise<ListResponseType<StayType>> {
    return stayGetList(params)
      .then(result => {
        if (result) {
          commit('SET_STAYS', result);
        }

        return result || AgGridResponse;
      })
      .catch(() => AgGridResponse);
  },
};
