import { GetterTree } from 'vuex';
import { StoreType } from '@/types/store/store.type';
import { InfoStoreType } from '@/types/store/info-store.type';

export const getters: GetterTree<InfoStoreType, StoreType> = {
  getActivities(state) {
    return state.activities;
  },

  getLanguages(state) {
    return state.languages;
  },

  getCountries(state) {
    return state.countries;
  },

};
