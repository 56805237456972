import { i18n } from '@/i18n';
import { TranslateResult } from 'vue-i18n';
import { PermissionListType } from '@/types/api/user/permission-list.type';

export const PERM_CAN_LOGIN_ADMIN = 'admin_canLoginAdmin';
export const PERM_CAN_EDIT_PERMISSIONS = 'admin_canEditPermissions';
export const PERM_CAN_MANAGE_ADMINS = 'admin_canManageAdmins';
export const PERM_CAN_MANAGE_USERS = 'admin_canManageUsers';
export const PERM_CAN_MANAGE_EVENTS = 'admin_canManageEvents';

export const defaultPermissionsValues = {
  [PERM_CAN_LOGIN_ADMIN]: false,
  [PERM_CAN_EDIT_PERMISSIONS]: false,
  [PERM_CAN_MANAGE_ADMINS]: false,
  [PERM_CAN_MANAGE_USERS]: false,
  [PERM_CAN_MANAGE_EVENTS]: false,
};

export const hasPermission = (
  permission: string,
  permissions?: PermissionListType
): boolean => !permissions || permissions[permission] === undefined ? false : permissions[permission];

export const getPermissionLabel = (perm: string): TranslateResult => {
  const labelsMap: {[key: string]: string} = {
    [PERM_CAN_LOGIN_ADMIN]: 'perm.admin_canLoginAdmin',
    [PERM_CAN_EDIT_PERMISSIONS]: 'perm.admin_canEditPermissions',
    [PERM_CAN_MANAGE_ADMINS]: 'perm.admin_canManageAdmins',
    [PERM_CAN_MANAGE_USERS]: 'perm.admin_canManageUsers',
    [PERM_CAN_MANAGE_EVENTS]: 'perm.admin_canManageEvents',
  };

  return i18n.t(labelsMap[perm]);
};

export const preparePermissionList = (permissions: PermissionListType): Omit<PermissionListType, 'id' | 'userId' | 'canLogin'> => {
  const perms: any = { ...permissions };

  delete perms.id;
  delete perms.userId;
  delete perms.canLogin;

  return perms;
};
