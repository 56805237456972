<template>
  <Layout>
    <h1 :class="$style.title">
      The page timed out while loading. Are you sure you're still connected to
      the Internet?
    </h1>
  </Layout>
</template>

<script>
  import { Component, Vue } from 'vue-property-decorator';
  import Layout from '@/components/layouts/main';

  @Component({
    page: {
      title: 'Page timeout',
      meta: [
        { name: 'description', content: 'The page timed out while loading.' },
      ],
    },
    components: { Layout },
  })
  export default class Timeout extends Vue {}
</script>

<style lang="scss" module>
  .title {
    text-align: center;
  }
</style>
