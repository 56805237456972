import Vue from 'vue';
import VueI18n from 'vue-i18n';
import ru from '@/i18n/messages/ru-RU.json';
import en from '@/i18n/messages/en-US.json';
import { http } from '@/api/http';
import { Settings } from 'luxon';

Vue.use(VueI18n);

export const enUS = 'en';
export const ruRU = 'ru';
export const LANGUAGES = [enUS, ruRU];

export const defaultLang = isAvailableLang(window.navigator.language) ? window.navigator.language : enUS;
export const i18n = new VueI18n({
  locale: defaultLang,
  fallbackLocale: defaultLang,
  messages: {
    en,
    ru,
  },
});

const loadedLanguages = [defaultLang]; // our default language that is preloaded
const languagesMap = {
  'en': 'en-US',
  'ru': 'ru-RU',
};

export function isAvailableLang(lang: string): boolean {
  return LANGUAGES.includes(lang);
}

function setI18nLanguage(lang: string) {
  i18n.locale = lang;
  http.defaults.headers.common['Accept-Language'] = lang;
  document.querySelector('html')!.setAttribute('lang', lang);
  Settings.defaultLocale = lang;

  return lang;
}

export function loadLanguageAsync(lang: string) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language hasn't been loaded yet
  return isAvailableLang(lang)
    ? import(/* webpackChunkName: "lang-[request]" */ `@/i18n/messages/${ languagesMap[lang] }.json`)
      .then(messages => {
          i18n.setLocaleMessage(lang, messages.default);
          loadedLanguages.push(lang);
          return setI18nLanguage(lang);
        }
      )
    : new Promise(resolve => resolve())
      .then(() => {
        loadedLanguages.push(lang);
        return setI18nLanguage(lang);
      });
}
