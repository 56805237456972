import { MutationTree } from 'vuex';
import { VenueStoreType } from '@/types/store/venue-store.type';

export const mutations: MutationTree<VenueStoreType> = {
  SET_VENUES(state, newValue) {
    state.venues = newValue;
  },

  SET_VENUE(state, newValue) {
    state.venue = newValue;
  },
};
