import { Route, RouteConfig } from 'vue-router';
import { store } from '@/store';

export const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'home',
    component: () => lazyLoadView(import('@/components/pages/dashboard/dashboard.vue')),
    meta: { authRequired: true },
    props: (/* route: Route */) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/users',
    name: 'users',
    component: () => lazyLoadView(import('@/components/pages/users/users.vue')),
    meta: { authRequired: true },
  },
  {
    path: '/user/update/:id',
    name: 'user-update',
    component: () => lazyLoadView(import('@/components/pages/users/user-update/userUpdate.vue')),
    meta: { authRequired: true },
  },
  {
    path: '/events',
    name: 'events',
    component: () => lazyLoadView(import('@/components/pages/events/events.vue')),
    meta: { authRequired: true },
  },
  {
    path: '/event/create',
    name: 'event-create',
    component: () => lazyLoadView(import('@/components/pages/events/event-create/eventCreate.vue')),
    meta: { authRequired: true },
  },
  {
    path: '/event/update/:id',
    name: 'event-update',
    component: () => lazyLoadView(import('@/components/pages/events/event-update/eventUpdate.vue')),
    meta: { authRequired: true },
  },
  {
    path: '/spots',
    name: 'spots',
    component: () => lazyLoadView(import('@/components/pages/spots/spots.vue')),
    meta: { authRequired: true },
  },
  {
    path: '/stays',
    name: 'stays',
    component: () => lazyLoadView(import('@/components/pages/stays/stays.vue')),
    meta: { authRequired: true },
  },
  {
    path: '/spot/create',
    name: 'spot-create',
    component: () => lazyLoadView(import('@/components/pages/spots/spot-create/spotCreate.vue')),
    meta: { authRequired: true },
  },
  {
    path: '/spot/update/:id',
    name: 'spot-update',
    component: () => lazyLoadView(import('@/components/pages/spots/spot-update/spotUpdate.vue')),
    meta: { authRequired: true },
  },
  // {
  //   path: '/venues',
  //   name: 'venues',
  //   component: () => lazyLoadView(import('@/components/pages/venues/venues.vue')),
  //   meta: { authRequired: true },
  // },
  // {
  //   path: '/venue/create',
  //   name: 'venue-create',
  //   component: () => lazyLoadView(import('@/components/pages/venues/venue-create/venueCreate.vue')),
  //   meta: { authRequired: true },
  // },
  // {
  //   path: '/venue/update/:id',
  //   name: 'venue-update',
  //   component: () => lazyLoadView(import('@/components/pages/venues/venue-update/venueUpdate.vue')),
  //   meta: { authRequired: true },
  // },
  {
    path: '/activities',
    name: 'activities',
    component: () => lazyLoadView(import('@/components/pages/activities/activities.vue')),
    meta: { authRequired: true },
  },
  {
    path: '/activities/create',
    name: 'activities-create',
    component: () => lazyLoadView(import('@/components/pages/activities/activities-create/activitiesCreate.vue')),
    meta: { authRequired: true },
  },
  {
    path: '/activities/update/:id',
    name: 'activities-update',
    component: () => lazyLoadView(import('@/components/pages/activities/activities-update/activitiesUpdate.vue')),
    meta: { authRequired: true },
  },
  {
    path: '/offer/:id',
    name: 'offer-card',
    component: () => lazyLoadView(import('@/components/pages/offers/offer-card/offer-card.vue')),
    meta: { authRequired: true },
  },
  {
    path: '/login',
    name: 'login',
    component: () => lazyLoadView(import('@/components/pages/login/login.vue')),
    meta: {
      beforeResolve(routeTo: Route, routeFrom: Route, next: any) {
        checkLoggedIn(routeTo, routeFrom, next);
      },
    },
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      beforeResolve(routeTo: Route, routeFrom: Route, next: any) {
        store.dispatch('auth/logOut');
        next({ name: 'login' });
      },
    },
  },
  {
    path: '/404',
    name: '404',
    component: require('@/components/features/404.vue').default,
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    redirect: '404',
  },
];

function checkLoggedIn(routeTo: Route, routeFrom: Route, next: any) {
  // If the user is already logged inc
  if (store.getters['auth/loggedIn']) {
    // Redirect to the home page instead
    next({ name: 'home' });
  } else {
    // Continue to the login page
    next();
  }
}

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@views/my-view')
//
function lazyLoadView(AsyncView: Promise<any>) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: require('@/components/features/loading.vue').default,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 400,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    error: require('@/components/features/timeout.vue').default,
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000,
  });

  return Promise.resolve({
    functional: true,
    render(h: any, { data, children }: any) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children)
    },
  })
}
