<script>
  import appConfig from '@/app.config';

  export default {
    page: {
      // All subcomponent titles will be injected into this template.
      titleTemplate(title) {
        title = typeof title === 'function' ? title(this.$store) : title;
        return title ? `${this.$t(title)} | ${this.$t(appConfig.title)}` : this.$t(appConfig.title)
      },
    },
  }
</script>

<template>
  <div id="app">
    <modals-container />
    <v-dialog/>

    <!--
    Even when routes use the same component, treat them
    as distinct and create the component again.
    -->
    <RouterView :key="$route.fullPath" />
  </div>
</template>

<!-- This should generally be the only global CSS in the app. -->
<style lang="scss">
  // Allow element/type selectors, because this is global CSS.
  // stylelint-disable selector-max-type, selector-class-pattern

  // Design variables and utilities from src/design.
  @import '@/design';
  @import '../node_modules/ag-grid-community/dist/styles/ag-grid.css';
  @import '../node_modules/ag-grid-community/dist/styles/ag-theme-balham.css';
  @import '../node_modules/ag-grid-community/dist/styles/ag-theme-material.css';
  @import '../node_modules/vue-snack/dist/vue-snack.min.css';
  @import '../node_modules/vue2-daterange-picker/dist/vue2-daterange-picker.css';
  @import '../node_modules/leaflet/dist/leaflet.css';
  @import '../node_modules/codemirror/lib/codemirror.css';
  @import '../node_modules/@toast-ui/editor/dist/toastui-editor.css';
  @import '../node_modules/@toast-ui/editor/dist/toastui-editor-viewer.css';
  @import '../node_modules/leaflet-geosearch/assets/css/leaflet.css';
  @import '../node_modules/vue-multiselect/dist/vue-multiselect.min.css';
</style>
