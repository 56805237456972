import { MutationTree } from 'vuex';
import { InfoStoreType } from '@/types/store/info-store.type';

export const mutations: MutationTree<InfoStoreType> = {
  SET_ACTIVITIES(state, newValue) {
    state.activities = newValue;
  },

  SET_LANGUAGES(state, newValue) {
    state.languages = newValue;
  },

  SET_COUNTRIES(state, newValue) {
    state.countries = newValue
  },

};
