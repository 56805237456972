import { http } from '@/api/http';
import { AxiosRequestConfig } from 'axios';
import {
  EventsCreateRequest,
  EventsRequest, EventsUpdateLocaleRequest,
  EventsUpdateRequest
} from '@/types/request/events-request.types';
import { ListResponseType } from '@/types/response/list-response.type';
import { EventType } from '@/types/api/events/event.type';
import { EventLocaleType } from '@/types/api/events/event-locale.type';

export const eventsGetList = (
  params: EventsRequest,
  options: AxiosRequestConfig = {}
): Promise<ListResponseType<EventType>> => {
  return http
    .get('/admin/event', { params, ...options })
    .then(response => response.data);
};

export const eventsGetById = (id: number, options: AxiosRequestConfig = {}): Promise<EventType> => {
  return http
    .get(`/admin/event/${id}`, { ...options })
    .then(response => response.data);
};

export const eventsCreate = (
  params: EventsCreateRequest,
  options: AxiosRequestConfig = {}
): Promise<EventType> => {
  const formData = new FormData();

  params.image.forEach(item => {
    formData.append('image', item);
  });

  if (params.schedule && params.schedule.length) {
    formData.append('schedule', params.schedule.map(item => JSON.stringify(item)).join(';'));
  }

  if (params.appMeta && params.appMeta.length) {
    formData.append('appMeta', JSON.stringify(params.appMeta));
  }

  if (params.featuredCountry && params.featuredCountry.length) {
    formData.append('featuredCountry', JSON.stringify(params.featuredCountry));
  }

  if (params.locationInfo) {
    formData.append('locationInfo', JSON.stringify(params.locationInfo));
  }

  Object.entries(params).map(([key, value]) => {
    if (value && key !== 'image' && key !== 'schedule' && key !== 'appMeta' && key !== 'locationInfo' && key !== 'featuredCountry') {
      formData.set(key, value);
    }
  });

  return http
    .post('/admin/event', formData, {
      ...options,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => response.data);
};

export const eventsUpdate = (
  id: number,
  params: EventsUpdateRequest,
  options: AxiosRequestConfig = {}
): Promise<EventType> => {
  const formData = new FormData();

  if (params.image) {
    params.image.forEach(item => {
      formData.append('image', item);
    });
  }

  if (params.schedule && params.schedule.length) {
    formData.append('schedule', params.schedule.map(item => JSON.stringify(item)).join(';'));
  }

  if (params.appMeta && params.appMeta.length) {
    formData.append('appMeta', JSON.stringify(params.appMeta));
  }

  if (params.locationInfo) {
    formData.append('locationInfo', JSON.stringify(params.locationInfo));
  }

  if (params.featuredCountry && params.featuredCountry.length) {
    formData.append('featuredCountry', JSON.stringify(params.featuredCountry));
  }

  Object.entries(params).map(([key, value]) => {
    if (value !== undefined && key !== 'image' && key !== 'schedule' && key !== 'appMeta' && key !== 'locationInfo' && key !== 'featuredCountry') {
      formData.set(key, value);
    }
  });

  return http
    .put(`/admin/event/${id}`, formData, {
      ...options,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => response.data);
};

export const eventsDelete = (id: number, options: AxiosRequestConfig = {}): Promise<EventType> => {
  return http.delete(`/admin/event/${id}`, { ...options })
    .then(response => response.data);
};

export const eventsCreateLocale = (
  id: number,
  params: EventsUpdateLocaleRequest,
  options: AxiosRequestConfig = {}
): Promise<EventLocaleType> => {
  return http
    .put(`/admin/event/${id}/locale`, params, { ...options })
    .then(response => response.data);
};

export const eventsUpdateLocale = (
  id: number,
  params: EventsUpdateLocaleRequest,
  options: AxiosRequestConfig = {}
): Promise<EventLocaleType> => {
  return http
    .put(`/admin/event/locale/${id}`, params, { ...options })
    .then(response => response.data);
};

export const eventsDeleteLocale = (id: number, options: AxiosRequestConfig = {}): Promise<EventLocaleType> => {
  return http.delete(`/admin/event/locale/${id}`, { ...options })
    .then(response => response.data);
};

export const eventsUpdateImages = (id: number, params: File[], options: AxiosRequestConfig = {}): Promise<EventType> => {
  const formData = new FormData();

  if (params) {
    params.forEach(item => {
      formData.append('image', item);
    });
  }

  return http
    .put(`/admin/event/${id}/image`, formData, {
      ...options,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => response.data);
};

export const eventsDeleteImage = (id: number, imageId: string, options: AxiosRequestConfig = {}): Promise<EventType> => {
  return http.delete(`/admin/event/${id}/image/${imageId}`, { ...options })
    .then(response => response.data);
};
