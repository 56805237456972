import { CountryType } from '@/types/api/info/country.type';
import { ActionTree } from 'vuex';
import { StoreType } from '@/types/store/store.type';
import { ListResponseType } from '@/types/response/list-response.type';
import { AgGridResponse } from '@/helpers/ag-grid/ag-grid-response';
import { InfoStoreType } from '@/types/store/info-store.type';
import { ActivityType } from '@/types/api/info/activity.type';
import { InfoActivityRequest } from '@/types/request/info-request.types';
import { infoActivities, infoCountries, infoLanguages } from '@/api/info.api';
import { LanguageType } from '@/types/api/info/language.type';

export const actions: ActionTree<InfoStoreType, StoreType> = {
  getActivities({ commit }, params: InfoActivityRequest): Promise<ListResponseType<ActivityType>> {
    return infoActivities(params)
      .then(result => {
        if (result) {
          commit('SET_ACTIVITIES', result);
        }

        return result || AgGridResponse;
      })
      .catch(() => AgGridResponse);
  },

  getLanguages({ commit }): Promise<LanguageType[]> {
    return infoLanguages()
      .then(result => {
        if (result) {
          commit('SET_LANGUAGES', result);
        }

        return result;
      });
  },

  getCountries({ commit }): Promise<CountryType[]> {
    return infoCountries()
      .then(result => {
        if (result) {
          commit('SET_COUNTRIES', result)
        }

        return result;
      })
  }
};
