import { ActionTree } from 'vuex';
import { StoreType } from '@/types/store/store.type';
import { LangStoreType } from '@/types/store/lang-store.type';
import { loadLanguageAsync } from '@/i18n';

export const actions: ActionTree<LangStoreType, StoreType> = {

  changeLang({ commit }, lang: string) {
    loadLanguageAsync(lang).then(() => {
      commit('SET_CURRENT_LANG', lang);
    });
  },
};
