import { http } from '@/api/http';
import { AxiosRequestConfig } from 'axios';
import { ListResponseType } from '@/types/response/list-response.type';
import {
  VenuesCreateRequest,
  VenuesLinkSpotRequest,
  VenuesLinkStayRequest,
  VenuesRequest, VenuesUpdateRequest
} from '@/types/request/venue-request.types';
import { VenueType } from '@/types/api/venue/venue.type';

export const venuesGetList = (
  params: VenuesRequest,
  options: AxiosRequestConfig = {}
): Promise<ListResponseType<VenueType>> => {
  return http
    .get('/admin/venue', { params, ...options })
    .then(response => response.data);
};

export const venuesGetById = (id: number, options: AxiosRequestConfig = {}): Promise<VenueType> => {
  return http
    .get(`/admin/venue/${id}`, { ...options })
    .then(response => response.data);
};

export const venuesCreate = (
  params: VenuesCreateRequest,
  options: AxiosRequestConfig = {}
): Promise<VenueType> => {
  return http.post('/admin/venue', params, { ...options })
    .then(response => response.data);
};

export const venuesUpdate = (
  id: number,
  params: VenuesUpdateRequest,
  options: AxiosRequestConfig = {}
): Promise<VenueType> => {
  return http.put(`/admin/venue/${id}`, params, { ...options })
    .then(response => response.data);
};

export const venuesDelete = (id: number, options: AxiosRequestConfig = {}): Promise<VenueType> => {
  return http
    .delete(`/admin/venue/${id}`, { ...options })
    .then(response => response.data);
};

export const venuesLinkSpot = (params: VenuesLinkSpotRequest, options: AxiosRequestConfig = {}): Promise<VenueType> => {
  return http
    .put(`/admin/venue/${params.venueId}/spot/${params.spotId}`, { ...options })
    .then(response => response.data);
};

export const venuesLinkStay = (params: VenuesLinkStayRequest, options: AxiosRequestConfig = {}): Promise<VenueType> => {
  return http
    .put(`/admin/venue/${params.venueId}/stay/${params.stayId}`, { ...options })
    .then(response => response.data);
};

export const venuesUnlinkStay = (params: VenuesLinkStayRequest, options: AxiosRequestConfig = {}): Promise<VenueType> => {
  return http
    .delete(`/admin/venue/${params.venueId}/stay/${params.stayId}`, { ...options })
    .then(response => response.data);
};
