import { ActionTree } from 'vuex';
import { StoreType } from '@/types/store/store.type';
import { ActivityStoreType } from '@/types/store/activity-store.type';
import {
  ActivityCreateLocaleRequest,
  ActivityCreateRequest, ActivityReferenceCreateRequest,
  ActivityReferenceUpdateRequest, ActivitySubCreateRequest, ActivitySubUpdateRequest, ActivityUpdateLocaleRequest
} from '@/types/request/activity-request.types';
import { EventActivityType } from '@/types/api/activity/event-activity.type';
import {
  activityCreate,
  activityCreateLocale,
  activityDelete,
  activityDeleteLocale,
  activityEventCreate,
  activityEventDelete,
  activityGetById,
  activitySpotCreate,
  activitySpotDelete,
  activitySubCreate,
  activitySubCreateLocale,
  activitySubDelete,
  activitySubUpdate, activitySubUpdateLocale,
  activityUpdate,
  activityUpdateLocale
} from '@/api/activity.api';
import { SpotActivityType } from '@/types/api/activity/spot-activity.type';
import { ActivityType } from '@/types/api/info/activity.type';
import { ActivityLocaleType } from '@/types/api/info/activity-locale.type';
import { SubActivityType } from '@/types/api/info/sub-activity.type';
import { SubActivityLocaleType } from '@/types/api/info/sub-activity-locale.type';

export type ActivityCard<T> = {
  id: number;
  params: T;
}

export const actions: ActionTree<ActivityStoreType, StoreType> = {
  getActivity({ commit }, id: number): Promise<ActivityType> {
    return activityGetById(id)
      .then(result => {
        if (result) {
          commit('SET_ACTIVITY', result);
        }

        return result;
      });
  },

  createActivity({ commit }, params: ActivityReferenceCreateRequest): Promise<ActivityType> {
    return activityCreate(params)
      .then(result => result);
  },

  updateActivity({ commit }, data: ActivityCard<ActivityReferenceUpdateRequest>): Promise<ActivityType> {
    return activityUpdate(data.id, data.params).then(result => result);
  },

  deleteActivity({ commit }, id: number): Promise<ActivityType> {
    return activityDelete(id).then(result => result);
  },

  createLocale({ commit }, data: ActivityCard<ActivityCreateLocaleRequest>): Promise<ActivityLocaleType> {
    return activityCreateLocale(data.id, data.params).then(result => result);
  },

  updateLocale({ commit }, data: ActivityCard<ActivityUpdateLocaleRequest>): Promise<ActivityLocaleType> {
    return activityUpdateLocale(data.id, data.params).then(result => result);
  },

  deleteLocale({ commit }, id: number): Promise<ActivityLocaleType> {
    return activityDeleteLocale(id).then(result => result);
  },

  activityEventCreate({ commit }, data: ActivityCard<ActivityCreateRequest>): Promise<EventActivityType> {
    return activityEventCreate(data.id, data.params)
      .then(result => result);
  },

  activitySpotCreate({ commit }, data: ActivityCard<ActivityCreateRequest>): Promise<SpotActivityType> {
    return activitySpotCreate(data.id, data.params)
      .then(result => result);
  },

  activityEventDelete({ commit }, id: number): Promise<EventActivityType> {
    return activityEventDelete(id).then(result => result);
  },

  activitySpotDelete({ commit }, id: number): Promise<SpotActivityType> {
    return activitySpotDelete(id).then(result => result);
  },

  activitySubCreate({ commit }, data: ActivitySubCreateRequest): Promise<SubActivityType> {
    return activitySubCreate(data)
      .then(result => result);
  },

  activitySubUpdate({ commit }, data: ActivityCard<ActivitySubUpdateRequest>): Promise<SubActivityType> {
    return activitySubUpdate(data.id, data.params)
      .then(result => result);
  },

  activitySubDelete({ commit }, id: number): Promise<SubActivityType> {
    return activitySubDelete(id).then(result => result);
  },

  activitySubCreateLocale({ commit }, data: ActivityCard<ActivityCreateLocaleRequest>): Promise<SubActivityLocaleType> {
    return activitySubCreateLocale(data.id, data.params).then(result => result);
  },

  activitySubUpdateLocale({ commit }, data: ActivityCard<ActivityUpdateLocaleRequest>): Promise<SubActivityLocaleType> {
    return activitySubUpdateLocale(data.id, data.params).then(result => result);
  },
};
