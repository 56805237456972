import { state } from './state';
import { actions } from './actions';
import { mutations } from './mutations';
import { getters } from './getters';
import { Module } from 'vuex';
import { StoreType } from '@/types/store/store.type';
import { ActivityStoreType } from '@/types/store/activity-store.type';

const namespaced: boolean = true;
export const activity: Module<ActivityStoreType, StoreType> = {
  namespaced,
  state,
  actions,
  mutations,
  getters
};
