import { http } from '@/api/http';
import { CountryType } from '@/types/api/info/country.type';
import { AxiosRequestConfig } from 'axios';
import { ListResponseType } from '@/types/response/list-response.type';
import { InfoActivityRequest } from '@/types/request/info-request.types';
import { ActivityType } from '@/types/api/info/activity.type';
import { LanguageType } from '@/types/api/info/language.type';

export const infoActivities = (
  params: InfoActivityRequest,
  options: AxiosRequestConfig = {}
): Promise<ListResponseType<ActivityType>> => {
  return http
    .get('/info/activities', { params, ...options })
    .then(response => response.data);
};

export const infoLanguages = (options: AxiosRequestConfig = {}): Promise<LanguageType[]> => {
  return http
    .get('/info/language', { ...options })
    .then(response => response.data);
};

export const infoCountries = (options: AxiosRequestConfig = {}): Promise<CountryType[]>  => {
  return http.get('/info/countries', { ...options }).then(response => response.data);
}
