<template>
  <Layout>
    <Transition appear>
      <div :class="$style.loadingIcon" name="sync" spin></div>
    </Transition>
  </Layout>
</template>

<script>
  import { Component, Vue } from 'vue-property-decorator';
  import Layout from '@/components/layouts/main';

  @Component({
    page: {
      title: 'common.loadingPage',
      meta: [{ name: 'description', content: 'Loading page...' }],
    },
    components: { Layout },
  })
  export default class Loading extends Vue {}
</script>

<style lang="scss" module>
  .loadingIcon {
    display: block;
    margin: 0 auto;

    // stylelint-disable-next-line selector-class-pattern
    &:global(.v-enter-active) {
      transition: opacity 1s;
    }

    // stylelint-disable-next-line selector-class-pattern
    &:global(.v-enter) {
      opacity: 0;
    }
  }
</style>
