import { ActionTree } from 'vuex';
import { AuthStoreType } from '@/types/store/auth-store.type';
import { StoreType } from '@/types/store/store.type';
import { UserSignUpPhoneCodeRequest } from '@/types/request/auth-request.types';
import { userLogout, userMe, userSignUp } from '@/api/user.api';

export const actions: ActionTree<AuthStoreType, StoreType> = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  init() {},

  signUp({ commit, dispatch, getters }, params: UserSignUpPhoneCodeRequest): Promise<string> {
    if (getters.loggedIn) return dispatch('validate');

    return userSignUp(params)
      .then((response) => {
        return dispatch('validate')
          .then(() => {
            return response.status;
          });
      });
  },

  // Logs out the current user.
  logOut({ commit }) {
    commit('SET_CURRENT_USER', null);
    return userLogout();
  },

  // Validates the current user's token and refreshes it
  // with new data from the API.
  validate({ commit }) {
    return userMe()
      .then((user) => {
        commit('SET_CURRENT_USER', user);
        return user;
      })
      .catch((error) => {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
          commit('SET_CURRENT_USER', null);
        }
        return null;
      });
  },
};
