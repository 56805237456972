import { ActionTree } from 'vuex';
import { StoreType } from '@/types/store/store.type';
import { AgGridResponse } from '@/helpers/ag-grid/ag-grid-response';
import { UsersStoreType } from '@/types/store/users-store.type';
import {
  UsersRequest, UsersStatisticsRequest,
  UsersUpdatePermissionsRequest,
  UsersUpdateRequest
} from '@/types/request/users-request.types';
import { UserType } from '@/types/api/user/user.type';
import { userById, userDelete, userList, userStatistics, userUpdate, userUpdatePermissions } from '@/api/user.api';
import { PermissionListType } from '@/types/api/user/permission-list.type';
import { UserStatisticsType } from '@/types/api/user/user-statistics.type';
import { ListResponseType } from '@/types/response/list-response.type';

export type UserCard<T> = {
  id: number;
  params: T;
}

export const actions: ActionTree<UsersStoreType, StoreType> = {
  getUsers({ commit }, params: UsersRequest): Promise<ListResponseType<UserType>> {
    return userList(params)
      .then(result => {
        if (result) {
          commit('SET_USERS', result);
        }

        return result || AgGridResponse;
      })
      .catch(() => AgGridResponse);
  },

  getUser({ commit }, id: number): Promise<UserType> {
    return userById(id)
      .then(result => {
        console.log(result);
        if (result) {
          commit('SET_USER', result);
        }

        return result;
      });
  },

  updateUser({ commit }, data: UserCard<UsersUpdateRequest>): Promise<UserType> {
    return userUpdate(data.id, data.params).then(result => result);
  },

  deleteUser({ commit }, id: number): Promise<void> {
    return userDelete(id).then(result => result);
  },

  updatePermissions({ commit }, data: UserCard<UsersUpdatePermissionsRequest>): Promise<PermissionListType> {
    return userUpdatePermissions(data.id, data.params)
      .then(result => result);
  },

  getUserStatistics({ commit }, params: UsersStatisticsRequest = {}): Promise<UserStatisticsType> {
    return userStatistics(params)
      .then(result => {
        if (result) {
          commit('SET_USER_STATISTICS', result);
        }

        return result;
      });
  },
};
