import Vue from 'vue';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { i18n } from '@/i18n';
import { store } from '@/store';

export const getBaseUrl = process.env.VUE_APP_API_URL  || '/api';
export const http = createAxios();

export const setDefaultAuthHeaders = (accessToken: string) => {
  http.defaults.headers.common.Authorization = accessToken ? `Bearer ${accessToken}` : '';
};

export const removeDefaultAuthHeaders = () => {
  delete axios.defaults.headers.common.Authorization;
};

export function createAxios(options?: AxiosRequestConfig): AxiosInstance {
  const axiosInstance = axios.create({
    baseURL: getBaseUrl,
    headers: {
      'Content-Type': 'application/json',
    },
    ...options
  });
  axiosInstance.interceptors.response.use(response => response, errorResponseHandler);

  return axiosInstance;
}

function errorResponseHandler(error: AxiosError) {
  const errorMessage = error.response && error.response.data
      ? (error.response.data.errorMessage || i18n.t('common.serverError')) : i18n.t('common.serverError');

  if (error.response) {
    error.response.data = {
      isSuccess: false,
      errorMessage
    };
  }

  if (error.response && error.response.status !== 403) {
    Vue.prototype.$snack.danger(errorMessage);
  } else {
    if (store.getters['auth/loggedIn']) {
      store.commit('SET_CURRENT_USER', null);
      location.reload();
    }
  }

  return Promise.reject(error);
}
