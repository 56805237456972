import { UserSignUpPhoneCodeRequest } from '@/types/request/auth-request.types';
import { UserSignUpPhoneCodeResponse } from '@/types/response/auth-response.types';
import { http } from '@/api/http';
import { AxiosRequestConfig } from 'axios';
import { UserType } from '@/types/api/user/user.type';
import {
  UsersRequest, UsersStatisticsRequest,
  UsersUpdatePermissionsRequest,
  UsersUpdateRequest
} from '@/types/request/users-request.types';
import { PermissionListType } from '@/types/api/user/permission-list.type';
import { UserStatisticsType } from '@/types/api/user/user-statistics.type';
import { ListResponseType } from '@/types/response/list-response.type';

export const userSignUp = (
  params: UserSignUpPhoneCodeRequest,
  options: AxiosRequestConfig = {}
): Promise<UserSignUpPhoneCodeResponse> => {
  return http
    .post('/admin/login', params, options)
    .then(response => response.data);
};

export const userLogout = (options: AxiosRequestConfig = {}): Promise<void> => {
  return http
    .post('/admin/logout', {}, options)
    .then(response => response.data);
};

export const userMe = (options: AxiosRequestConfig = {}): Promise<UserType> => {
  return http
    .get('/admin/me', options)
    .then(response => response.data);
};

export const userList = (
  params: UsersRequest,
  options: AxiosRequestConfig = {}
): Promise<ListResponseType<UserType>> => {
  return http
    .get('/admin/user', { params, ...options })
    .then(response => response.data);
};

export const userById = (
  id: number,
  options: AxiosRequestConfig = {}
): Promise<UserType> => {
  return http
    .get(`/admin/user/${id}`, { ...options })
    .then(response => response.data);
};

export const userDelete = (id: number, options: AxiosRequestConfig = {}): Promise<void> => {
  return http
    .delete(`/admin/user/${id}`, { ...options })
    .then(response => response.data);
};

export const userUpdatePermissions = (
  id: number,
  params: UsersUpdatePermissionsRequest,
  options: AxiosRequestConfig = {}
): Promise<PermissionListType> => {
  return http
    .put(`/admin/user/${id}/permissions`, params, options)
    .then(response => response.data);
};

export const userUpdate = (
  id: number,
  params: UsersUpdateRequest,
  options: AxiosRequestConfig = {}
): Promise<UserType> => {
  return http
    .put(`/admin/user/${id}`, params, options)
    .then(response => response.data);
};

export const userStatistics = (
  params: UsersStatisticsRequest,
  options: AxiosRequestConfig = {}
): Promise<UserStatisticsType> => {
  return http
    .get('/admin/user/statistics', { params, ...options })
    .then(response => response.data);
};
