import { GetterTree } from 'vuex';
import { StoreType } from '@/types/store/store.type';
import { VenueStoreType } from '@/types/store/venue-store.type';

export const getters: GetterTree<VenueStoreType, StoreType> = {
  getVenues(state) {
    return state.venues;
  },

  getVenue(state) {
    return state.venue;
  },
};
