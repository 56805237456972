import { GetterTree } from 'vuex';
import { StoreType } from '@/types/store/store.type';
import { OfferStoreType } from '@/types/store/offer-store.type';

export const getters: GetterTree<OfferStoreType, StoreType> = {
  getSpotOffers(state) {
    return state.spotOffers;
  },

  getSpotOffer(state) {
    return state.spotOffer;
  },

  getSpotOfferUsers(state) {
    return state.spotOfferUsers;
  },

  getEventOffers(state) {
    return state.eventOffers;
  },

  getEventOffer(state) {
    return state.eventOffer;
  },

  getEventOfferUsers(state) {
    return state.eventOfferUsers;
  },
};
