import { state } from './state';
import { actions } from './actions';
import { mutations } from './mutations';
import { getters } from './getters';
import { Module } from 'vuex';
import { StoreType } from '@/types/store/store.type';
import { SpotStoreType } from '@/types/store/spot-store.type';

const namespaced: boolean = true;
export const spot: Module<SpotStoreType, StoreType> = {
  namespaced,
  state,
  actions,
  mutations,
  getters
};
