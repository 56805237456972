import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { store } from './store';
import BootstrapVue from 'bootstrap-vue';
import { i18n } from '@/i18n';
import VModal from 'vue-js-modal';
import VueTelInput from 'vue-tel-input';
import VueSnackbar from 'vue-snack';
import '@/helpers/validation.helper';
import L from 'leaflet';
import * as GmapVue from 'gmap-vue';

/** Fix leaflet icons **/
delete (L.Icon.Default.prototype as any)._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
/** Fix leaflet icons **/

// Don't warn about using the dev version of Vue in development.
Vue.config.productionTip = process.env.NODE_ENV === 'production';

Vue.use(BootstrapVue);
Vue.use(VueTelInput);
Vue.use(VModal, { dynamic: true, dialog: true });
Vue.use(VueSnackbar, { time: 5000, close: true });
Vue.use(GmapVue, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
    libraries: 'places', // This is required if you use the Autocomplete plugin
  },
  installComponents: true, // false - If manually install components
});

store.dispatch('auth/validate')
  .then(() => {
    // Get references
    Promise.all([
      store.dispatch('info/getLanguages'),
      store.dispatch('info/getCountries')
    ]).then(() => start())
  });

function start() {
  const app = new Vue({
    router,
    store,
    i18n,
    render: h => h(App as any),
  }).$mount('#app');

  VModal.rootInstance = app;
}
