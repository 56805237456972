<template>
  <!-- ========== Left Sidebar Start ========== -->
  <div class="left-side-menu">
    <VuePerfectScrollbar
      v-if="!isCondensed"
      v-once
      class="slimscroll-menu"
      :settings="settings"
    >
      <SideNav :is-condensed="isCondensed" />
    </VuePerfectScrollbar>
    <SideNav v-else :is-condensed="isCondensed" />

    <!-- Sidebar -left -->
  </div>
  <!-- Left Sidebar End -->
</template>

<script>
  import { Component, Vue } from 'vue-property-decorator';
  import VuePerfectScrollbar from 'vue-perfect-scrollbar';
  import SideNav from './side-nav/side-nav.vue';

  @Component({
    components: { VuePerfectScrollbar, SideNav },
    props: {
      isCondensed: {
        type: Boolean,
        default: false,
      },
    }
  })
  export default class SideBar extends Vue {
    settings = {
      minScrollbarLength: 60,
    };
  }
</script>

<style lang="scss">
  .slimscroll-menu {
    height: 100%;
  }

  .ps > .ps__scrollbar-y-rail {
    width: 8px !important;
    background-color: transparent !important;
  }

  .ps > .ps__scrollbar-y-rail > .ps__scrollbar-y,
  .ps.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail > .ps__scrollbar-y,
  .ps > .ps__scrollbar-y-rail:active > .ps__scrollbar-y,
  .ps > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y {
    width: 6px !important;
  }

  @media screen and (max-width: 767.98px) {
    .left-side-menu {
      padding: 0;
    }
  }
</style>
